.App {
  height: inherit;
  text-align: center;
  display: grid;
  grid-template-columns: 20px 1fr 2px;
  grid-template-rows: auto 1fr 2.5em;
  grid-template-areas:
    'header header header'
    'main main main'
    'footer footer footer';
}

.Header {
  grid-area: header;
}

.Content {
  grid-area: main;
}

.Footer {
  grid-area: footer;
}

html,
body {
  height: 100%;
  background-color: #d1f9d1;
  background-color: white;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('Assets/Fonts/century-gothic/CenturyGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}